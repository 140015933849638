import {ExperimentNames} from '@wix/wix-events-commons-statics'
import type {ExperimentsBag} from '@wix/yoshi-flow-editor'
import type {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isZeroInsteadOfFree = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.ZeroInsteadOfFree)

export const isNewReservationsServiceEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.NewReservationsService)
